import React from 'react';

class Hubspot extends React.Component {

    componentDidMount(){
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
        this.instance.appendChild(s);
    }

    render(){
        return <div className="meetings-iframe-container" data-src={this.props.link} ref={el => (this.instance = el)} />;
    }
}

export default Hubspot;